import React from 'react';
import { Container, Section } from '../../global';

const HoursOfOperation = () => (
  <Section id="operation-hours">
    <div name="contact-info" className="section-row two-col">
      <div className="col-left">
        <p>3838 Martin Luther King Jr Pkwy<br/>
          Des Moines, Iowa 50310</p><br/>
        <p>Monday - Friday 6:30 am - 5:30 pm </p>
        <a href="tel:515-255-6184" className="wya-link">(515) 255-6184</a><br/>
        <p>
          <i className="fab fa-facebook"></i>
        </p>
      </div>
      <div className="col-right">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2982.1610320945206!2d-93.65409638426199!3d41.63065057924263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87ee9be52a7e61b5%3A0x2e982358c6c83750!2s3838%20Martin%20Luther%20King%20Jr%20Pkwy%2C%20Des%20Moines%2C%20IA%2050310!5e0!3m2!1sen!2sus!4v1583609129401!5m2!1sen!2sus"
            width="600" height="450" frameBorder="0" allowFullScreen=""></iframe>
      </div>
    </div>
  </Section>
);

export default HoursOfOperation;
