import React from 'react';
import { Container, Section } from '../global';
import styled from 'styled-components';
import CurrentOpenings from '../sections/ChildInquiryContent/CurrentOpenings'
import TuitonInfo from '../sections/ChildInquiryContent/Tuition'
import HoursOfOperation from '../sections/ChildInquiryContent/HoursOfOperation'


const ChildInquiry = () => (
  <Section id="child-inquiry">
      <h1>Child Inquiry</h1>
      <div className="two-col section-row">
          <div className="col-left">
              <TuitonInfo/>
          </div>

          <div clas="col-right">
              <CurrentOpenings/>
          </div>
      </div>
      <HoursOfOperation/>
      <p class="text-center">Interested in Wonder Years Academy? Complete the form below and we will be in touch soon!</p><br/>
      <StyledContainer>
          <iframe title='Child Inquiry' src="https://docs.google.com/forms/d/e/1FAIpQLSf9SnlTA8Cv-d4Zu1HPgfsRMJmlhl6q3prWAzHx7t-3qxqskA/viewform?embedded=true" width="640" height="1675" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </StyledContainer>
  </Section>
);

const StyledContainer = styled(Container)`
  display: flex;
  position: relative;
  justify-content: center;
`;

export default ChildInquiry;
