import Layout from '../components/common/Layout';
import Navbar from '../components/common/Navbar';
import Footer from '../components/sections/Footer';
import React from 'react';
import Application from '../components/sections/ChildInquiry';

const ChildInquiryPage = () => (
    <Layout>
        <Navbar/>
        <div class="main-content">
            <Application/>
        </div>
        <Footer/>
    </Layout>
);

export default ChildInquiryPage;
