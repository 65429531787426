import React from 'react';
import { Container, Section } from '../../global';

const CurrentOpenings = () => (
  <Section id="current-openings">
  <h2>Current Openings</h2>
    <Container>
      <ul>
        <li>Infant Room - Contact center for next available opening </li>
        <li>Mobile Room - Contact center for next available opening </li>
        <li>Two’s Room - Contact center for next available opening </li>
        <li>Preschool Room - Contact center for next available opening </li>
        <li>School-Age - 2 Openings </li>
      </ul>
    </Container>
  </Section>
);

export default CurrentOpenings;