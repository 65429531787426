import React from 'react';
import { Container, Section } from '../../global';

const TuitionInfo = () => (
  <Section id="tuition-info">
  <h2>Tuition</h2>
    <Container>
      <ul>
        <li>Infant Room - $232.50/ week</li>
        <li>Mobile Room -  $232.50/week</li>
        <li>Two’s Room - $232.50/week</li>
        <li>Preschool Room - $200/week</li>
        <li>School-Age - $80/ week (Summer session $160/week)</li>
      </ul>
    </Container>
  </Section>
);

export default TuitionInfo;
